<template>
  <div class="staked-info-progress">
    <div :class="rowClass">
      <ui-text v-if="hasStaked" variant="body4" color="textSecondary">
        My staked
      </ui-text>
      <ui-text variant="body4" color="textSecondary">TVL</ui-text>
    </div>
    <div :class="rowClass">
      <ui-text v-if="hasStaked" variant="body4" color="textSecondary">
        {{ useUSDFormat(data.staked.amountUSD) }}
      </ui-text>
      <ui-text variant="body4" color="textSecondary">
        {{ useUSDFormat(data.tvl.amountUSD) }}
      </ui-text>
    </div>
    <ui-progress-bar
      :percentage="stakedPercentage"
      class="staked-info-progress__progress-bar"
    />
    <div :class="rowClass">
      <ui-text v-if="hasStaked" variant="body4" color="textSecondary">
        {{ useTokenNumberFormat(data.staked.amount, { token: data.token }) }}
      </ui-text>
      <ui-text variant="body4" color="textSecondary">
        {{ useTokenNumberFormat(data.tvl.amount, { token: data.token }) }}
      </ui-text>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { AllTokenType } from '@deorderbook/shared'
import { Big } from 'big.js'

export interface StakedInfoProgressData {
  /**
   * Token type of TVL and staked amount.
   * Parameter for {@link useTokenNumberFormat}
   */
  token: AllTokenType
  tvl: {
    /**
     * Token value of TVL amount.
     * Parameter for {@link useTokenNumberFormat}
     */
    amount: number | string
    /**
     * USD value of TVL amount.
     * Parameter for {@link useUSDFormat}
     */
    amountUSD: number | string
  }
  staked: {
    /**
     * Token value of user's staked amount.
     * Parameter for {@link useTokenNumberFormat}
     */
    amount: number | string
    /**
     * USD value of user's staked amount.
     * Parameter for {@link useUSDFormat}
     */
    amountUSD: number | string
  }
}

const props = defineProps({
  data: {
    type: Object as PropType<StakedInfoProgressData>,
    required: true,
  },
})

const { data } = toRefs(props)

const hasStaked = computed(() => {
  return Big(data.value.staked.amount || 0).gt(0)
})

const stakedPercentage = computed(() => {
  if (Big(data.value.tvl.amount).lte(0)) return 0
  return Big(data.value.staked.amount)
    .div(data.value.tvl.amount)
    .times(100)
    .toNumber()
})

const rowClass = computed(() => {
  return [
    'staked-info-progress__row',
    {
      '--justify-right': !hasStaked.value,
    },
  ]
})
</script>
<style lang="postcss" scoped>
.staked-info-progress {
  display: flex;
  flex-direction: column;
}
.staked-info-progress__row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  &.--justify-right {
    justify-content: flex-end;
  }
}
.staked-info-progress__progress-bar {
  margin: 4px 0;
}
</style>
