<template>
  <progress
    class="ui-progress-bar"
    :value="String(progressPercentage)"
    max="100"
  ></progress>
</template>
<script setup lang="ts">
const props = defineProps({
  percentage: {
    type: [String, Number],
    required: true,
    default: 0,
    validator: (value: string | number): boolean => {
      const num = Number(value)
      return num >= 0 && num <= 100
    },
  },
})
const progressPercentage = computed(() => {
  if (+percentage.value <= 0) {
    return '0'
  } else if (+percentage.value >= 100) {
    return '100'
  } else return String(percentage.value)
})
const { percentage } = toRefs(props)
</script>
<style lang="postcss" scoped>
.ui-progress-bar {
  width: 100%;
  height: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ui-progress-bar::-webkit-progress-bar {
  background-color: var(--brand-color-secondary);
  border-radius: 0;
  opacity: 0.5;
}

.ui-progress-bar::-webkit-progress-value {
  background-color: var(--brand-color-primary);
  border-radius: 0;
}

.ui-progress-bar::-moz-progress-bar {
  background-color: var(--brand-color-primary);
  border-radius: 0;
}
</style>
